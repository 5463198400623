<template>
  <QDialog
    ref="ApplyDialog"
    :maximized="$q.screen.lt.sm"
    @hide="$emit('hide')"
  >
    <QCard
      flat
      class="bg-grey-2 full-width"
      style="min-width: 85vw"
    >
      <QCardSection>
        <div class="flex justify-between">
          <AppContent
            path="component.dialogs.apply_dialog.apply"
            class="text-dark text-h6 text-weight-bold text-center"
          />
          <HCButton
            is-close
            @hide="$emit('hide')"
          />
        </div>
        <!-- Preview of trnTarget -->
        <!-- company : logo, candidature spontanée chez companyName -->
        <div  v-if="step === 1" class="row">
          <div
            class="col-12 col-sm-3 text-center q-py-sm"
            :class="{ 'flex flex-center': $q.screen.lt.md }"
          >
            <AppAvatar
              :entity="activeCompany"
              :size="$q.screen.gt.sm ? '100px' : '50px'"
            />
            <AppContent
              path="component.dialogs.apply_dialog.at"
              class="text-weight-bold q-ml-sm"
              :options="{ name: activeCompany.name }"
            />
          </div>
          <OfferBuild
            v-if="targetIsOffer"
            :offer="trnTarget"
            :company="activeCompany"
            class="col-12 col-sm-9 q-mt-sm"
            mini
          />
          <div
            v-else
            class="col-12 col-sm-9"
          >
            <AppContent path="component.dialogs.apply_dialog.spontaneous" />
            <AppContent
              path="component.dialogs.apply_dialog.at"
              :options="{ name: activeCompany.name }"
            />
          </div>
        </div>
      </QCardSection>

      <!-- Form & Files -->
      <div
        v-if="step === 1"
        class="bg-white q-pa-md"
      >
        <div
          v-if="hasAlreadyApply"
          class="q-gutter-y-md q-py-sm text-dark text-center"
        >
          <AppContent
            v-if="hasAlreadyApply"
            path="component.dialogs.apply_dialog.already_sent"
            class="text-h5 text-weight-bolder"
          />
          <div class="flex flex-center q-gutter-md">
            <HcButton
              v-close-popup
              label="prompt.previous_button"
              variant="negative"
            />
          </div>
        </div>
        <QForm
          v-else-if="!$_.get(user, 'metadata._files.resume', false)"
          ref="registerForm"
          @submit="signupProcess"
        >
          <div class="row flex justify-evenly items-center">
            <AppContent path="component.dialogs.apply_dialog.connect" />
            <HcButton
              :href="$app({ name: 'login' })"
              label="authentication.log_in_button"
            />
          </div>
          <QSeparator class="q-my-md" />
          <div class="row">
            <div class="col-12 col-sm-6 q-pa-xs">
              <QInput
                v-for="(field, i) of userFields"
                :key="i"
                :model-value="$_.get(user, field.key)"
                :label="$t({ id: field.label })"
                :mask="field.mask ?? undefined"
                :rules="field.rules"
                unmasked-value
                class="br-25"
                debounce="500"
                lazy-rules
                dense
                outlined
                @update:model-value="$_.set(user, field.key, $event)"
              >
                <template #prepend>
                  <QIcon
                    color="primary"
                    :name="field.icon"
                  />
                </template>
              </QInput>
              <div class="flex justify-between no-wrap q-mt-sm">
                <PhoneInput
                  :form-object="user"
                  :step="{
                    field: 'metadata._private.phone',
                    label: 'user.phone',
                  }"
                  class="full-width"
                  light
                  :dense="true"
                  show-icon
                  @stepResult="$event.canGoNext ? user = $_.merge(user, $event.value) : ''"
                />
              </div>

              <div class="flex justify-between no-wrap q-mt-sm">
                <QToggle
                  v-model="asset.customAttributes.accountantBackground"
                  :label="$t('profile.accountantBackground.active')"
                  color="primary"
                  checked-icon="uil:check"
                  unchecked-icon="uil:times"
                />
                <QBtn
                  class="text-primary"
                  icon="uil:question-circle"
                  flat
                  round
                >
                  <ActionTooltip :str="$t('profile.accountantBackground.hint')" />
                </QBtn>
              </div>
              <QField
                :model-value="$_.get(user, 'platformData._private.cguAccepted', false)"
                :rules="[requiredField]"
                @update:model-value="$_.set(user, 'platformData._private.cguAccepted', $event)"
              >
                <template #control>
                  <QCheckbox
                    :model-value="$_.get(user, 'platformData._private.cguAccepted', false)"
                    color="positive"
                    @update:model-value="$_.set(user, 'platformData._private.cguAccepted', $event)"
                  >
                    <div class="flex items-center no-wrap">
                      <AppContent
                        class="cursor-pointer"
                        path="form.application.accept_CGU"
                      />
                      <HCButton
                        icon="uil:external-link-alt"
                        @click="openCGU"
                      />
                    </div>
                  </QCheckbox>
                </template>
              </QField>
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
              <FileUploader
                ref="ApplyUploadResume"
                :upload-config="resumeUploadConfig"
                @added="resumeError = false"
                @uploaded="resumeUploaded($event)"
              />
              <AppContent
                v-if="resumeError"
                class="text-body2 text-negative q-ma-xs"
                path="form.upload_required"
              />
              <FileUploader
                ref="ApplyUploadLetter"
                class="q-mt-sm"
                :upload-config="letterUploadConfig"
                @added="shouldUploadLetter = true"
                @uploaded="updateTransaction($event)"
              />
              <QSelect
                v-if="$_.get(trnTarget, 'locations', []).length > 1"
                v-model="preferredLocation"
                outlined
                :options="trnTarget.locations"
                option-label="city"
                :label="$t('component.dialogs.apply_dialog.select_address_label')"
              />
            </div>
            <div class="col-12 q-pa-xs q-mt-md flex justify-center">
              <HcButton
                :loading="loading"
                :disable="loading || hasAlreadyApply || resumeError"
                variant="positive"
                class="q-mb-md"
                label="component.dialogs.apply_dialog.apply_short"
                type="submit"
              />
            </div>
          </div>
        </QForm>
        <!-- Direct application -->
        <div
          v-else
          class="q-gutter-y-md text-dark text-center"
        >
          <AppContent
            class="text-h5 text-weight-bolder"
            path="component.dialogs.apply_dialog.app_type"
            :options="{ isDefault: isDefault }"
          />
          <AppContent
            class="text-h6 q-mt-xs q-bt-md"
            path="component.dialogs.apply_dialog.really_sure"
          />
          <div class="row">
            <div
              v-if="$_.get(trnTarget, 'locations', []).length > 1"
              class="col-12 col-sm-6 q-pa-xs"
            >
              <QSelect
                v-model="preferredLocation"
                outlined
                :options="trnTarget.locations"
                option-label="city"
                :label="$t('component.dialogs.apply_dialog.select_address_label')"
              />
            </div>
            <div :class="['col-12 q-pa-xs', { 'col-sm-6': !$_.get(trnTarget, 'locations', []).length > 1 }]">
              <FileUploader
                ref="ApplyUploadLetter"
                :upload-config="letterUploadConfig"
                @added="shouldUploadLetter = true"
                @uploaded="updateTransaction($event)"
              />
            </div>
          </div>
          <div class="flex flex-center q-gutter-md">
            <HcButton
              v-close-popup
              label="prompt.cancel_button"
              variant="secondary"
            />
            <HcButton
              :loading="loading"
              :disable="loading || hasAlreadyApply"
              variant="positive"
              label="form.application.application_success_title"
              @click="createTransaction"
            />
          </div>
        </div>
        <IndeedTagLanding
          v-if="$_.get(transactionData, '_analytics.source', false) === 'indeed'"
          :job-id="trnTarget.id"
        />
      </div>

      <QCardSection
        v-if="step === 2"
        class="bg-white"
      >
        <!-- Apply success -->
        <div class="text-center q-gutter-y-sm">
          <div class="flex flex-center">
            <QIcon
              size="md"
              color="positive"
              name="uil:check"
            />
            <AppContent
              class="text-h5"
              path="component.dialogs.apply_dialog.application_sent"
            />
          </div>
          <AppContent
            class="text-subtitle2 text-grey-5 q-mt-xs q-bt-md"
            path="component.dialogs.apply_dialog.sent_to"
            :options="{ name: activeCompany.name }"
          />
          <div class="flex flex-center q-mt-sm">
            <HCButton
              color="positive"
              :to="{ name: 'searchCompanies' }"
              force-label-on-xs
              icon="uil:search"
              label="component.dialogs.apply_dialog.see_companies"
            />
            <HCButton
              color="secondary"
              is-close
              force-label-on-xs
              label="prompt.close_button"
            />
          </div>
          <QSeparator />
        </div>


        <!-- Set password -->
        <div
          v-if="showPass"
          class="q-mt-sm"
        >
          <AppContent
            class="text-h6"
            path="component.dialogs.apply_dialog.set_password_title"
          />
          <div class="flex items-center q-gutter-sm">
            <template v-if="!passChanged">
              <div class="row">
                <AppContent
                  class="col-12 col-md-3 text-body2 text-grey-5"
                  path="component.dialogs.apply_dialog.set_password"
                />
                <div class="col-12 col-md-3 q-pa-xs">
                  <QInput
                    v-model="inputPassword1"
                    type="password"
                    :label="$t('form.password')"
                    dense
                    outlined
                    :rules="[atLeastEight]"
                    lazy-rules
                  />
                </div>
                <div class="col-12 col-md-3 q-pa-xs">
                  <QInput
                    v-model="inputPassword2"
                    type="password"
                    :label="$t('form.confirm_password')"
                    dense
                    outlined
                    :rules="[atLeastEight, val => val === inputPassword1 || $t('form.error.passwords_differs')]"
                    lazy-rules
                  />
                </div>
                <div class="col-12 col-md-3 q-pa-xs text-center">
                  <HcButton
                    label="component.dialogs.apply_dialog.finish_account"
                    variant="positive"
                    @click="setUserPassword"
                  />
                </div>
              </div>
            </template>
            <div
              v-else-if="passChanged"
              class="flex flex-center"
            >
              <QIcon
                size="md"
                color="positive"
                name="uil:check"
              />
              <AppContent
                class="text-body2"
                path="component.dialogs.apply_dialog.password_changed"
              />
            </div>
          </div>
          <QSeparator class="q-mt-sm" />
        </div>

        <!-- Enforce profile completion -->
        <div
          v-if="$uElements('profileAsset')"
          class="q-mt-sm"
        >
          <AppContent
            class="text-h6"
            path="profile.profile"
          />
          <AppContent
            v-if="$_.get($uElements('profileAsset'), 'customAttributes.jobReferential', false)"
            class="text-grey-5"
            path="component.dialogs.apply_dialog.profile_identified"
            :options="{
              profile: $t({ id: `customAttributes.jobReferential.${$_.get($uElements('profileAsset'), 'customAttributes.jobReferential', false)}` })
            }"
          />
          <AppContent path="component.dialogs.apply_dialog.profile_completion" />
          <div class="text-center q-my-sm">
            <HcButton
              label="pages.profile.edit_btn"
              :href="$app({ name: 'profileForm' })"
            />
          </div>
          <QSeparator />
        </div>

        <!-- Similar results -->
        <div v-if="$_.get(similarResults, 'results.length', false)">
          <div class="flex items-center justify-between">
            <AppContent
              class="text-h6 q-my-sm"
              :path="`component.dialogs.apply_dialog.similar_${targetIsOffer ? 'offer' : 'company'}`"
            />
            <HCButton
              v-if="!similarSent"
              icon="uil:fast-mail"
              :label="`Envoyer ma candidature à ces ${similarSelected.length} ${targetIsOffer ? 'offres' : 'cabinets'}`"
              color="positive"
              force-label-on-xs
              :loading="similarLoading"
              :disable="!similarSelected.length"
              @click="sendSimilarApplications"
            />
          </div>
          <div
            v-if="!similarSent"
            class="row q-col-gutter-md"
          >
            <div
              v-for="sim of $_.get(similarResults, 'results', [])"
              :key="sim.id"
              class="col-12 col-md-4 relative-position"
            >
              <component
                :is="targetIsOffer ? 'OfferCard' : 'CompanyCard'"
                :asset="sim"
                :company="$_.get(sim, 'company', undefined)"
                mini
              />
              <div
                class="absolute-top-right"
                style="top: 20px; right:5px;"
              >
                <QCheckbox
                  :model-value="similarSelected.includes(sim.id)"
                  color="primary"
                  size="md"
                  @update:model-value="similarSelected = $_.xor(similarSelected, [sim.id])"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-center">
              <QIcon
                size="md"
                color="positive"
                name="uil:check"
              />
              <AppContent
                class="text-subtitle2 text-grey-5 q-mt-xs q-bt-md"
                path="component.dialogs.apply_dialog.similar_sent"
                :options="{
                  nb: similarSelected.length
                }"
              />
            </div>
          </div>
        </div>

        <TalentPixel v-if="$_.get(transactionData, '_analytics.source', false) === 'talent'" />
        <IndeedPixel v-if="$_.get(transactionData, '_analytics.source', false) === 'indeed'" />
        <IndeedTagApplied
          v-if="$_.get(transactionData, '_analytics.source', false) === 'indeed'"
          :job-id="trnTarget.id"
          :application-id="transaction.id"
        />
      </QCardSection>
    </QCard>
  </QDialog>
</template>

<script>
import ValidationMixins from 'hc-core/mixins/validation'
import OfferBuild from 'hc-core/components/builds/offer-build'
import AppAvatar from 'hc-core/components/common/app-avatar'
import FileUploader from 'hc-core/components/files/file-uploader'
import OfferCard from 'hc-core/components/cards/offer-card'
import CompanyCard from 'hc-core/components/cards/company-card'
import IndeedPixel from 'hc-core/components/pixels/indeed'
import TalentPixel from 'hc-core/components/pixels/talent'
import IndeedTagApplied from 'hc-core/components/pixels/indeed-tag-applied'
import IndeedTagLanding from 'hc-core/components/pixels/indeed-tag-landing'
import PhoneInput from 'hc-core/components/forms/phone-input'

export default {
  components: {
    OfferBuild,
    AppAvatar,
    FileUploader,
    OfferCard,
    CompanyCard,
    TalentPixel,
    IndeedPixel,
    IndeedTagApplied,
    IndeedTagLanding,
    PhoneInput,
  },
  mixins: [ValidationMixins],
  props: {
    trnTarget: {
      // Can be both offer as company for spontaneous applications
      type: Object,
      required: true
    }
  },
  emits: ['hide'],
  data () {
    return {
      // Dialog
      step: 1,
      loading: false,
      showPass: false,
      passChanged: false,
      resumeError: false,
      shouldUploadLetter: false,

      // User
      user: {
        roles: ['applicant'],
        metadata: { _resume: {}, _private: {} },
        platformData: { _private: { cguAccepted: false } },
      },
      asset: {
        customAttributes: {
          accountantBackground: false,
        },
        metadata: { _resume: { library: true, publicProfile: true } },
      },
      sIdGenerated: null,
      inputPassword1: null,
      inputPassword2: null,

      // Transaction
      transaction: null,
      preferredLocation: null,

      // Similiar
      similarResults: [],
      similarSelected: [],
      similarLoading: false,
      similarSent : false,
    }
  },
  computed: {
    activeCompany () { return this.$store.getters['asset/activeCompany'] },
    targetIsOffer () {
      return this.$_.get(this.trnTarget, 'assetTypeId', 'typ_c3YRL1E1Ikh1kfndiIkh') === this.$store.getters['common/offerTypeId']
    },
    isDefault () {
      return (this.targetIsOffer && this.trnTarget.categoryId === this.defaultCategoryId) || (!this.targetIsOffer) ? 'spontanée' : ''
    },
    hasAlreadyApply () {
      return this.$uElements('applications').find(app => app.assetId === this.$_.get(this.trnTarget, 'id', false)) !== undefined
    },
    userFields () {
      return [
        { key: 'lastname', label: 'user.lastname_label', icon: 'uil:user', rules: [this.requiredField] },
        { key: 'firstname', label: 'user.firstname_label', icon: 'uil:user', rules: [this.requiredField] },
        { key: 'email', label: 'user.email_address_label', icon: 'uil:envelope-alt', rules: [this.requiredField, this.isValidEmailOrEmpty, this.checkEmailAvailable] },
        // { key: 'metadata._private.phone', label: 'user.phone_number_label', icon: 'uil:phone-alt', mask: '## ## ## ## ##', rules: [this.requiredField, this.isValidPhone] },
      ]
    },
    transactionData () {
      const rQuery = this.$_.get(this.$route, 'query', {})
      let source = this.$_.get(rQuery, 'callback', false) ? this.$_.get(rQuery, 'callback').replace('apply_', '') : 'happycab'
      if (this.$_.get(rQuery, 'callback', false) === 'apply') source = 'indeed'
      let channel = this.$_.get(rQuery, 'channel', this.$_.get(rQuery, 'utm_medium', null))
      if (!channel && !this.targetIsOffer) channel = 'spontaneous'
      return {
        _analytics: {
          action: this.targetIsOffer ? 'offerApplication' : 'companyApplication',
          source: source ?? 'happycab',
          channel: channel ? channel.toLowerCase() : null
        }
      }
    },
    resumeUploadConfig () {
      return {
        id: this.$_.get(this.user, 'id', null), // Let it on user so we ahve resume_usr_..
        entity: this.user, // Let it on user so we ahve resume_usr_..
        label: 'profile.resume.add',
        hint: 'profile.resume.add_hint',
        uploadFolder: 'files/resume',
        uploadPrefix: 'resume',
        contentType: 'application/pdf',
        autoUpload: false,
        useEntityId: true,
        field: 'metadata._files.resume',
      }
    },
    letterUploadConfig () {
      return {
        id: this.$_.get(this.transaction, 'id', null),
        entity: this.transaction,
        label: 'profile.letter.add',
        hint: 'profile.letter.add_hint',
        uploadFolder: 'files/letters',
        uploadPrefix: 'letter',
        contentType: 'application/pdf',
        autoUpload: false,
        useEntityId: true,
        field: 'metadata._files.letter',
      }
    },
  },
  async mounted () {
    this.sIdGenerated = require('shortid').generate()
    if (this.currentUser) {
      this.user = this.currentUser
      await this.$store.dispatch('auth/getUserOrganizations', { userId: this.currentUser.id })
    }
    if (process.env.DEV && !this.user.id) {
      this.user = this.$_.merge(this.user, {
        firstname: `firstname_${this.sIdGenerated}`,
        lastname: `lastname_${this.sIdGenerated}`,
        password: '01 02 03 04 05',
        email: `email_${this.sIdGenerated}@yopmail.com`,
        metadata: {
          _private: { phone: '01 02 03 04 05' },
        }
      })
    }
  },
  methods: {
    // Dialog
    async signupProcess () {
      try {
        // Check if resume is added
        if (this.resumeError || this.loading) return
        this.loading = true

        // User
        if (!this.user.id) {
          this.showPass = true
          this.$_.set(this.user, 'password', this.sIdGenerated)
          this.user = this.$_.merge(
            await this.$store.dispatch('auth/signup', {
              user: this.$_.omit(this.user, ['metadata', 'platformData'])
            }),
            this.user
          )
          await this.$store.dispatch('auth/getUserOrganizations', { userId: this.currentUser.id })
          this.$refs.ApplyUploadResume.$refs.FileUploader.upload()
        } else await this.createTransaction(true)
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },

    // User
    async resumeUploaded (uptUsr) {
      try {
        // TEMPORARY : keep it on applicants too
        this.$_.set(uptUsr, 'attrs.metadata', this.$_.merge(this.user.metadata, this.$_.get(uptUsr, 'attrs.metadata', {})))
        this.$_.set(uptUsr, 'attrs.platformData', this.$_.merge(this.user.platformData, this.$_.get(uptUsr, 'attrs.platformData', {}), this.transactionData))
        this.$_.set(uptUsr, 'id', this.user.id)
        try {
          this.user = await this.$store.dispatch('user/update', uptUsr)
        } catch (e) {}

        // Trigger parse process, which will create asset and add it to user
        this.$_.set(this.asset, 'customAttributes.resumeUpdate', new Date().toISOString())
        this.$_.set(this.asset, 'metadata._resume.email', this.user.email)
        this.$_.set(this.asset, 'metadata._resume.phone', this.$_.get(this.user, 'metadata._private.phone', undefined))
        this.$_.set(this.asset, 'locations', this.$_.compact(this.$_.get(this.asset, 'locations', [])))

        // Wait a short moment to ensure the document is uploaded to AWS
        await new Promise(resolve => setTimeout(resolve, 1000))
        try {
          await this.$store.dispatch('search/affindaParseProcess', { userId: this.user.id || this.currentUser.id, payload: this.asset })
        } catch (e) {}
        try {
          // Do it after parsing so can be sent to jobboards
          await this.createTransaction()
        } catch (e) {}
      } catch (e) { this.useLogger(e) }

    },

    async createTransaction (bypass = false) {
      try {
        if (this.loading && !bypass) return
        this.loading = true
        // Check if not already applied
        const existingTrn = await this.$store.dispatch('transaction/list', {
          params: {
            takerId: this.user.id,
            assetId: this.trnTarget.id
          }
        })
        if (existingTrn.paginationMeta.nbResults === 0) {
          this.transaction = await this.$store.dispatch('transaction/create', {
            attrs: {
              assetId: this.trnTarget.id,
              takerId: this.user.id,
              metadata: { preferredLocation: this.preferredLocation ?? undefined },
              platformData: this.transactionData
            }
          })
        }

        this.notifySuccess('form.application.application_sent')
        if (this.currentUser) await this.$store.dispatch('auth/getUserOrganizations', { userId: this.currentUser.id })
        if (this.shouldUploadLetter) this.$refs.ApplyUploadLetter.$refs.FileUploader.upload()
        this.step = 2

        // Show similar results
        if (this.trnTarget) {
          this.similarResults = await this.$store.dispatch('search/search', {
            location: this.$_.get(this.trnTarget, 'locations[0]', false) ? { latitude: this.trnTarget.locations[0].latitude, longitude: this.trnTarget.locations[0].longitude } : undefined,
            maxDistance: this.$_.get(this.trnTarget, 'locations[0]', false) ? 50000 : undefined,
            nbResultsPerPage: 3,
            similarTo: this.$_.get(this.trnTarget, 'id', undefined),
            without: this.$_.concat([this.$_.get(this.trnTarget, 'id', undefined)], this.$uElements('applications').map(app => app.assetId)),
            assetTypeId: this.$_.get(this.trnTarget, 'assetTypeId', undefined)
          })
          this.similarSelected = this.$_.get(this.similarResults, 'results', []).map(r => r.id)
        }
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },

    async updateTransaction (uptTrn) {
      setTimeout(async () => {
        if (!uptTrn.id && this.$_.get(this.transaction, 'id', false)) this.$_.set(uptTrn, 'id', this.transaction.id)
        if (this.$_.get(uptTrn, 'id', false)) await this.$store.dispatch('transaction/update', uptTrn)
      }, 2000) // Ensure document properly uploaded to AWS
    },

    openCGU () {
      window.open(this.$router.resolve({ path: '/cgu' }).href, '_blank')
    },

    // Set user password, only if user requested
    async setUserPassword () {
      try {
        if (this.inputPassword1 === this.inputPassword2) {
          await this.$store.dispatch('auth/changePassword', {
            currentPassword: this.sIdGenerated,
            newPassword: this.inputPassword1,
          })
          this.passChanged = true
        } else this.notifyError('notification.error')
      } catch (e) { this.useLogger(e) }
    },

    async sendSimilarApplications () {
      try {
        this.similarLoading = true
        for (const assetId of this.similarSelected) {
          const existingTrn = await this.$store.dispatch('transaction/list', {
            params: {
              takerId: this.currentUser.id,
              assetId
            }
          })
          if (existingTrn.paginationMeta.nbResults === 0) {
            await this.$store.dispatch('transaction/create', {
              attrs: {
                assetId,
                takerId: this.currentUser.id,
                platformData: {
                  _analytics: {
                    action: this.targetIsOffer ? 'offerApplication' : 'companyApplication',
                    source: 'happycab',
                  }
                }
              }
            })
          }
        }
        await this.$store.dispatch('auth/getUserOrganizations', { userId: this.currentUser.id })
        this.notifySuccess('form.application.application_sent')
        this.similarSent = true
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.similarLoading = false
      }
    },
  }
}
</script>

<style lang="sass" scoped>
.q-field--standard .q-field__control:before
  border-bottom: none !important
</style>
